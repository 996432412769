.contact-detail{
	padding: 30px 0;
	.contact-logo{
		margin-bottom: 10px;
		img.vc_single_image-img{
			max-width: 80px;
		}
	}
	h2.vc_custom_heading.custom-title1 {
	    margin-bottom: 15px;
	}
	table{
		tr{
			td{
				border: none;
				line-height: 20px;
				text-align: left;
				vertical-align: top;

				&:first-child{
					text-align: left;

					font-size: 0;
					i{
						font-size: 16px;
						padding-top: 3px;
						color: var(--color-green);
					}
				}
			}
		}
	}
	.wpb_column:last-child{
		@media only (--not_phone){
			padding-left: 40px;
		}
	}
}
.contact-form{
	padding-bottom: 30px;
	.wpb_text_column.wpb_content_element,
	h2.vc_custom_heading.custom-title1 {
	    margin-bottom: 5px;
	}
	.wpb_single_image.wpb_content_element.title-bar{
		margin-bottom: 20px;
	}

	.contact-split{

		@media only (--not_phone){
			display: flex;
			justify-content: space-between;
		}

		&>*{
			@media only (--not_phone){
				width: 32%;
			}
		}
	}

	
	input.wpcf7-form-control.wpcf7-text{
		background: none;
		border-color: var(--color-font);
		margin-bottom: 20px;
		font-size: 16px;

		&::-webkit-input-placeholder{
			color: var(--color-font) !important;
		}
	}
	textarea.wpcf7-form-control.wpcf7-textarea{
		background: none;
		border-color: var(--color-font);
		height: 60px;
		font-size: 16px;

		&::-webkit-input-placeholder{
			color: var(--color-font) !important;
		}
	}
	input[type="submit"]{
		background: var(--color-green);
		color: white;
		float: none;
		font-size: 16px;
		font-family: 'font-b';

		@media only (--not_phone){
			width: 32%;
		}
	}
	p:nth-last-child(2){
		text-align: center;
		padding-left: 1%;

	}
}