:root{
    --color-green: #92bf1e;
    --color-green-line: #80a01c;
    --color-font: #333333;
    --color-red: #fb755c;
    --color-grey: #90918e;
    --color-green-hover: rgba(146,191,54,0.6);
    --color-grey-hover: rgba(144,145,142,0.85);
    --color-orange: #edaa15;

    --color-green1: #3c8f46;
    --color-green2: #629544;
    --color-green3: #83a530;
    --color-green4: #92bf36;
    --color-green5: #addb40;

};

@font-face{
    font-family: 'font-b';
    src: url('https://project.inspiren.com.my/lnl/wp-content/themes/sydney/fonts/Mark Simonson - Proxima Nova Bold.otf');
}

@font-face{
    font-family: 'font-r';
    src: url('https://project.inspiren.com.my/lnl/wp-content/themes/sydney/fonts/Mark Simonson - Proxima Nova Regular.otf');
}

@font-face{
    font-family: 'font-custom';
    src: url('https://project.inspiren.com.my/lnl/wp-content/themes/sydney/fonts/Noteworthy-Lt.ttf');
}