   /* ####################################################################################

                                    TEMPLATE

/* ####################################################################################*/

footer#colophon {
    padding: 0px;
}

p.inspiren {
    /*bottom footer text formatting*/
    font-size: 12px;
    line-height: 1.5;
    margin-bottom: 0;
        float: left;
    padding-top: 6px;
}

.site-info.container {
    font-size: 12px;
    padding: 15px 0;
}
.site-info.container>.inspiren{
	font-size: 12px;
}
@media only screen and (min-width: 768px) {
    a.hoverInspiren {
        float: right;
    }
}

@media only screen and (max-width: 767px) {
    p.inspiren {
        text-align: center;
    }
    .reserve {
        display: block;
    }
}

.hoverInspiren:hover span {
    /*inspiren change color*/
    color: #ffe500;
    transition: all 0.3s;
}


.contact_table td {
    /*formating table*/
    padding: 0px;
    margin: 0px;
    text-align: left;
    border-collapse: collapse;
    border: 0px;
    color: white;
    font-size: 14px;
}


.entry-header h1,
.single .entry-header,
.page .entry-header {
    display: none !important;
}

.entry-footer {
    /*hide footer widget*/
    display: none;
}

.sidebar-column.col-md-3 {
    line-height: 20px;
}

#sidebar-footer h3.widget-title {
    padding-bottom: 20px;
}


.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"],
.contact-form textarea {
    border-color: white;
    background-color: rgba(0,0,0,0);
    color: white;
    padding: 4px 10px;
    font-size: 18px;
    font-weight: 300;
    width: 100%;

}
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"]{
    height: 40px;
}
.contact-form textarea {
    height: 80px;
}
.contact-form form.wpcf7-form {
    width: 100%;
    margin: auto;
}
textarea{
    resize: none !important;
}
textarea.wpcf7-form-control.wpcf7-textarea::-webkit-input-placeholder,

/*placeholder color to white*/

input.wpcf7-form-control.wpcf7-text::-webkit-input-placeholder {
    /*placeholder color to white*/
    color: white !important;
}

.contact-form input[type="reset"],
.contact-form input[type="submit"] {
    padding: 4px 50px 3px;
    /*border-radius: 20px;*/
    background: var(--color-yellow-form);
    /*border: 1px solid #004896;*/
    font-weight: 300;
    float: right;
    color: var(--color-font);

}


.contact-form input[type="reset"]:hover,
.contact-form input[type="submit"]:hover{ 
    /*background-color: #004896;*/
    /*color: white;*/
    transition: color 0.3s;
    transition: background-color 0.3s;
}

.bg-cover {
    background-repeat: no-repeat;
    height: 40vw;
    background-position: center;
    background-size: cover;
    min-height: 300px;
    /*background-attachment: fixed;*/
}

@media only screen and (min-width: 768px) {
    .contact-form input[type="text"],
    .contact-form input[type="email"],
    .contact-form input[type="tel"],
    .contact-form textarea {
        /*format text box */
        width: 100%;
    }
}

@media only screen and (max-width: 1024px){
#masthead .col-md-4.col-sm-8.col-xs-12 {
    width: 90%;
    text-align: left;
    margin-bottom: 0;
}
    #masthead .btn-menu {
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
}
    #masthead .col-md-8 {
    position: absolute;
    right: 0;
}
    #masthead .row {
    display: flex;
    align-items: center;
}
nav#mainnav-mobi.mainnav {
    top: 30px;
}
}

/*=====bottom contact button======*/
a.floating-button {
    position: fixed;
    width: 50px;
    height: 50px;
    line-height: 50px;
    bottom: 80px;
    right: 30px;
    font-size: 20px;
    background-color: #25D366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    z-index: 1000;
    animation: pulsess 3s infinite;
}
a.floating-button_email {
    bottom: 150px;
    background-color: #0e4980;
}
a.floating-button_phone {
    bottom: 220px;
    background-color: rgba(220, 16, 16, .45);
}
@keyframes pulsess {
  0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, .3);
  }
  30% {
    box-shadow: 0 0 0 5px rgba(0,0,0, 0.1);
  }
  50% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
  80% {
    box-shadow: 0 0 0 5px rgba(0,0,0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}

.grow figure {
    overflow: hidden;
}

.grow img {
    -moz-transition: -moz-transform 1s;
    -o-transition: -o-transform 1s;
    -webkit-transition: -webkit-transform 1s;
    transition: transform 1s;
}

.grow:hover img {
    -moz-transition: -moz-transform 1s;
    -o-transition: -o-transform 1s;
    -webkit-transition: -webkit-transform 1s;
    transition: transform 1s;
    transform: scale(1.15);
}

