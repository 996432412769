.service-row{
	margin: 20px 0 50px;

	.wpb_column:first-child{
		@media only (--not_phone){
			padding-right: 20px;
		}
	.wpb_text_column.wpb_content_element{
		margin-bottom: 0;
	}

	}
	
	@media only (--portable){
		.wpb_single_image{
			margin-bottom: 20px;
		}
	}

}

.service-row2{
	margin-bottom: 50px;
}

.service-reverse{

	@media only (--portable){
		display: flex;
		flex-direction: column;

		.vc_col-sm-6:first-child{
			order: 2;
		}
	}

}