.single {
	.post-navigation,
	section.related.products,
	.product_meta,
	.entry-thumb{
		display: none;
	}
}

.pdf a.vc_general {
    background: none;
    padding-left: 0;
    font-size: 16px;
    padding: 0;
}

.home-post.post-list .vc_grid-item-mini:hover:before{
	content: none;
}

.woocommerce.single .product {
	h1.product_title.entry-title{
		margin-bottom: 0;
	    font-size: 30px;
    	font-family: 'font-custom';
    	padding-bottom: 10px;
    	font-weight: 400;
	}
}

.woocommerce.single .woocommerce-breadcrumb{
	display: block;
	border-bottom: none;
	font-size: 0;
	margin-bottom: 20px;

	a:nth-child(2){
		font-size: 30px;
		font-family: 'font-b';
		color: var(--color-font);

	}
}

a.contact-enquiry {
    background: orange;
    padding: 5px 30px;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    border-radius: 5px;
    margin-top: 30px;
    display: inline-block;
}