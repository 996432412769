.bg-cover{
	height: 35vw;

	&>.wpb_column{
		height: 100%;
		display: flex;
		align-items: center;
	}

	h2.vc_custom_heading.cover-title{
		color: var(--color-font) !important;
		font-family: 'font-b';

		@media only (--portable){
			font-size: 40px;
		}
	}
}

.about-slogan {
	.big-title{
		margin-bottom: 5px;
		margin-top: 20px;
	}

	p{
		color: white;
	}
}

.about-about{
	margin-bottom: 30px;

	.wpb_single_image img{
		max-width: 70px;
		margin-bottom: 10px;
	}
	.title-bar img{
		max-width: 100%;
		margin-bottom: 0;
	}

	.wpb_column{

		&:first-child{
			@media only (--not_phone){
				padding-right: 20px;
			}
		}
		&:last-child{
			@media only (--not_phone){
				padding-left: 20px;
			}
		}
	}
}

.about-service{
	    padding: 70px 50px !important;
	    margin-bottom: 50px;

	    @media only (--portable){
			padding: 70px 0px !important;
	    }
	h2.wpb_heading.wpb_singleimage_heading{
		color: white;
	}
	.home-service{
		margin-bottom: 0;
	}
}


.vision-mission{
	&.split{
		.wpb_column{
			width: 49%;
			margin-bottom: 20px;

			@media only (--portable){
				width: 100%;
			}
			&:first-child{
				@media only (--not_phone){
					margin-right: 1%;
				}
			}
			&:last-child{
				@media only (--not_phone){
					margin-left: 1%;
				}
			}
		}
	}
	.wpb_column{
		color: white;

		&:before{
			content:"";
			position: absolute;
			height: 100%;
			width: 100%;
			background: var(--color-grey-hover);

		}
		&:hover:before{
			background: var(--color-green-hover);
		}
		.wpb_wrapper{
			position: relative;
		}

		ul{
			padding-left: 20px;
			li{
				color: white;
				line-height: 20px;
				padding-bottom: 10px;
			}
		}

		.vc_column-inner{
			padding: 30px 40px !important;

			.wpb_text_column.wpb_content_element{
				margin-bottom: 0;
			}
		}
	}
}

.stage{
	text-align: center;
	.stage-item{
		@media only (--not_phone){
			width: 30.3%;
		    display: inline-block;
		    margin: 1.5%;
		    height: 400px;
		}

		@media only (--portable){
			margin: 20px 0;
		}

		.vc_column-inner{
			padding: 0 !important;
		}

		.vc_inner{
			width: 80%;
			margin: auto;
		}

		.stage-text{
			background: white;
			width: 70%;
			text-align: center;
			padding-right: 20px;
			margin-bottom: 10px;
    		margin-top: 30px;
    		padding-top: 20px;
    		letter-spacing: 10px;
		}

		.stage-num{
			margin-bottom: 0;
			font-family: 'font-b';
		}
		.stage-title{
			margin-top: 0;
    		margin-bottom: 15px;
		}
		.stage-desc{
			ul{
				padding-left: 20px;
				color: white;

				li{
					line-height: 22px;
					padding-bottom: 5px;
					text-align: left;
				}
			}
		}
		&:first-child{
			background: var(--color-green1);

			.stage-text{
				color: var(--color-green1);
			}
		}
		&:nth-child(2){
			background: var(--color-green2);

			.stage-text{
				color: var(--color-green2);
			}
		}
		&:nth-child(3){
			background: var(--color-green3);

			.stage-text{
				color: var(--color-green3);
			}
		}
		&:nth-child(4){
			background: var(--color-green4);

			.stage-text{
				color: var(--color-green4);
			}
		}
		&:last-child{
			background: var(--color-green5);

			.stage-text{
				color: var(--color-green5);
			}
		}
	}
}