   /* ####################################################################################

                                    HOME

/* ####################################################################################*/
.wpb_revslider_element.wpb_content_element {
    margin: 0;
    margin-bottom: 80px;

    @media (--portable){
    	margin-bottom: 40px;
    }
}


.home-about{
	margin-bottom: 50px;

	h2.vc_custom_heading.custom-title{
		margin-bottom: 0;
	}

	.about-value{
		p{
			color: white;
			font-family: 'font-custom';
			line-height: 22px;
			margin-top: 10px;
		}

		.vc_row{
			margin: 0;
			@media (--not_phone){
				width: 48%;
				float: left;
				margin: 1%;

				.vc_column-inner {
					padding-left: 10px !important;
					padding-right: 10px !important;
				}
			}
		}
		@media only (--portable){
		.vc_row{
		margin-bottom: 10px;	
			&:nth-child(3){
				background: var(--color-green) !important;
			}
			&:last-child{
				background: var(--color-grey) !important;
			}
		}
		}
	}

	.wpb_singleimage_heading{
		color: white;
		font-size: 20px;
		margin-bottom: 0;
	}
	.wpb_column:last-child .wpb_single_image img{
		max-width: 70px;
	}

}

.custom-btn {
	a.vc_general {
	    padding: 2px 20px !important;
	    font-size: 16px;
	    background-image: none !important;
	}
}

.wpb_single_image.wpb_content_element{
	display: flex;
	flex-direction: column;
	margin-bottom: 0;

	h2.wpb_heading{
		order: 2;
		font-family: 'font-r';
	}
}

h2.vc_custom_heading.custom-title{
	margin-bottom: 15px;
	font-family: 'font-r';
}
h2.vc_custom_heading.custom-title2{
	font-family: 'font-custom';
}
.wpb_single_image.wpb_content_element.title-bar{
	margin-bottom: 20px;
}


.home-service{
	margin-bottom: 50px;
	h2.wpb_heading.wpb_singleimage_heading{
		font-size: 16px;
	    max-width: 115px;
	    margin: auto;
	    margin-top: 15px;
	}
	.wpb_single_image img{
		max-width: 70px;
	}

	@media only (--portable){
	.wpb_column {
		    width: 50%;
		    display: inline-block;
		    margin-bottom: 30px;
		}
	}
}

.home-project-cover{
	padding: 50px 0;
	&:before{
		content: '';
	    position: absolute;
	    height: 100%;
	    width: 100%;
	    background: rgba(0,0,0,0.85);
	    display: block !important;
	    top: 0;
	    left: 0;
	}

	p{
		color: white;
	    font-size: 45px;
	    line-height: 55px;

	    @media only (--portable){
	    	font-size: 20px;
	    	line-height: 30px;

	    	br{
	    		display: none;
	    	}
	    }
	}

}


.home-cat{
	@media only (--portable){
		margin-bottom: 50px;
	}
	.title-wrap{
		background: var(--color-grey);
		position: absolute;
		width: 100%;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 35px;
		transition: all 0.3s ease-in-out;

		h3{
			font-size: 20px;
			font-family: 'font-r';
			margin: 0;
			padding: 5px 0;
			color: white;
			font-weight: 400;
		}

		a.cate-more{
			height: 0;
			opacity: 0;
			height: 0;
		    color: white;
		    border: 1px solid white;
		    width: fit-content;
		    padding: 0 14px;
		    border-radius: 10px;
		    margin-top: 0;
		    transition: all 0.3s ease-in-out;
		    line-height: 24px;
		    font-weight: 400;
		}
	}
	.product-category{
		@media only (--portable){
			width: 100% !important;
		}
	}

	.product-category:hover{
		.title-wrap{
			background: var(--color-green-hover);
			height: 100%;
			transition: all 0.3s ease-in-out;

			a.cate-more{
				height: 27px;
				opacity: 1;
				margin-top: 10px;
				transition: all 0.3s ease-in-out;
			}
		}
	}
	.product img{
		margin-bottom: 0 !important;
	}
}


.vc_row.wpb_row.vc_row-fluid.home-brand{

.owl-item{
	.sa_hover_container{
		display: flex;
		justify-content: center;
		align-items: center;
		p{
			text-align: center;
		}
	}
}

	.owl-nav{
			display: block !important;

			.owl-prev{
				left: -10px;
				background: none !important;

				&:before{
					content: "\f104";
				    border: 1px solid #333;
				    color: #333;
				    -webkit-border-radius: 50%;
				    -moz-border-radius: 50%;
				    border-radius: 50%;
				    display: block;
				    font-family: fontAwesome;
				    font-size: 20px;
				    width: 25px;
				    height: 25px;
				    display: flex;
				    align-items: center;
				    justify-content: center;
				}

			}

			.owl-next{
				right: -10px;
				background: none !important;

				&:before{
					content: "\f105";
				    border: 1px solid #333;
				    color: #333;
				    -webkit-border-radius: 50%;
				    -moz-border-radius: 50%;
				    border-radius: 50%;
				    display: block;
				    font-family: fontAwesome;
				    font-size: 20px;
				    width: 25px;
				    height: 25px;
				    display: flex;
				    align-items: center;
				    justify-content: center;
				}
			}
		}
}



div#sidebar-footer{
	display: none;
}

.home div#sidebar-footer{
	display: block;
}