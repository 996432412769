.project-cover{
	background-image: url('https://project.inspiren.com.my/lnl/wp-content/uploads/2019/08/project-cover.jpg');
	margin-bottom: 50px;
}

.archive,.single{
	#content>.content-wrapper>.row{
		display: flex;

		@media (--portable){
			flex-direction: column;
		}

		#primary{
			order: 2;

			@media (--not_phone){
				width: 80%;
			}
		}


		#secondary{
			@media (--not_phone){
				width: 20%;
				padding-right: 4%;
			}
		}
	}
	h3.widget-title{
		display: none;
	}
}

.woocommerce{
	ul.product-categories{
		.cat-item{
			background: var(--color-grey-trans);
			padding: 3px 10px !important;
			margin-bottom: 5px;
			border-bottom: 1px solid rgba(51, 51, 51, 0.5);

			&:last-child{
				border-bottom: 0;
			}
			a{
				font-family: 'font-r';
			}

			&.current-cat{
				a{
					color: var(--color-green) !important;
				}
			}
		}
	}
}

.archive.woocommerce{
	.title-wrap{
		background: var(--color-grey);
		position: absolute;
		width: 100%;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 35px;
		transition: all 0.3s ease-in-out;

		h3{
			font-size: 20px;
			font-family: 'font-r';
			margin: 0;
			padding: 5px 0;
			color: white;
			font-weight: 400;
			text-transform: uppercase;
		}

		a.cate-more{
			height: 0;
			opacity: 0;
			height: 0;
		    color: white;
		    border: 1px solid white;
		    width: fit-content;
		    padding: 0 14px;
		    border-radius: 10px;
		    margin-top: 0;
		    transition: all 0.3s ease-in-out;
		    line-height: 24px;
		    font-weight: 400;
		}
	}

	li.product:hover{
		.title-wrap{
			background: var(--color-green-hover);
			height: 100%;
			transition: all 0.3s ease-in-out;

			a.cate-more{
				height: 27px;
				opacity: 1;
				margin-top: 10px;
				transition: all 0.3s ease-in-out;
			}
		}
	}
	.product img{
		margin-bottom: 0 !important;
		height: 200px;
		object-fit: cover;
	}

	.button{
		display: none;
	}

}

h3.archive-title,
.woocommerce-notices-wrapper,
p.woocommerce-result-count,
form.woocommerce-ordering,
nav.woocommerce-breadcrumb{
	display: none;
}


aside#woocommerce_product_categories-2 {
    padding-top: 0;
}
aside#text-4{
	margin-bottom: 0;
	
	.product-categories{
		margin-bottom: 0;
	}
	.cat-item{
		border-bottom: 1px solid rgba(51, 51, 51, 0.5);
	}
}
li.cat-item.cat-item-15{
	display: none;
}