   /* ####################################################################################

                                    OVERALL

/* ####################################################################################*/
.header-clone{
	display: none;
}



#content .container.content-wrapper{
	padding-top: 0;
}
#mainnav ul li ul:after{
	left: 5px !important;
}

header#masthead{
	padding: 5px;
	background: linear-gradient(white,transparent);
	position: fixed !important;

	li.menu-item{
		a{
			font-size: 16px;
		}
		@media (--desktop){
			padding: 0 2%;	
		}
	}

	li.current-menu-item a{
		color: var(--color-green);
		font-weight: 600;
	}
}

nav#mainnav{
	width: 100%;
}

body p{
	line-height: 30px;
}


#sidebar-footer{
	padding: 50px 0 20px 0;
	border-top: 10px solid var(--color-green);

	@media (--not_desktop){
		padding: 30px 0 0px
	}

	.sidebar-column {

		@media (--not_desktop){
			text-align: center;
		}

		&:first-child{
			padding-top: 30px;

			@media only screen and (min-width: 992px){
				width: 40%;
			}
			.widget_media_image{
				text-align: center;
				margin-bottom: 0;

				img{
					max-height: 100px;
					width: auto;
				}
			}
			.widget.widget_text{
				padding-top: 20px;
				h1{
					margin-top: 0;
				    font-size: 18px;
				    margin-bottom: 0;
				    color: #1a1a1a;
				    text-align: center;
				}
			}
	    }

	    &:nth-child(2){
	    	@media only screen and (min-width: 992px){
		    	width: 40%;
		    }
	    	table{
	    		tr{
	    			td{
	    				border: none;
	    				line-height: 20px;
	    				text-align: left;
	    				vertical-align: top;

	    				&:first-child{
	    					text-align: left;

	    					font-size: 0;
	    					i{
	    						font-size: 16px;
	    						padding-top: 3px;
	    					}
	    				}
	    			}
	    		}
	    	}
	    }

	    &:last-child{
	    	/*@media only (--sidebar_footer){*/
	    	@media only screen and (min-width: 992px){
		    	width: 20%;
		    }
	    	li.menu-item{
				
		    	a{
		    		text-transform: uppercase;
		    		&:hover{
		    			color: var(--color-green);
		    		}
		    	}
		    	&.current-menu-item{
				a{
					color: var(--color-green);
				}
		    	}
		    }
	    }

	    h3.widget-title{
	    	padding-bottom: 10px;
	    	color: var(--color-blue);
	    	font-weight: 700;
	    }

	}
}

.content-area .hentry:first-child {
    padding: 0;
}


footer#colophon{

	.site-info.container{
		text-align: center;
		padding: 5px 0;

		&>* {
		    /*display: inline-block;*/
		    /*float: none;*/
		    font-size: 14px;
		    margin-bottom: 0;
		}
		
		.copyright{

			@media only (--not_phone){
				float: left;
			}
			@media only (--portable){
				line-height: 24px;
			}
		}
		.inspiren{
			@media only (--not_phone){
				float: right;
			}
			@media only (--portable){
				width: 100%;
				text-align: center;
			}
		}
	
	}
}

@media (--not_phone){
a.hoverInspiren{
	float: none;
}
}

.head-contact {
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding-bottom: 15px;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--color-green-line);

	p{
		margin-bottom: 0;
		padding: 0 10px;
    	border: 1px solid var(--color-green-line);
    	border-radius: 10px;
    	line-height: 24px;

    	a{
    		color: var(--color-font);
    		display: table;
    	}

    	&:first-child, &:nth-child(2) {
    		display: table;
    		i{
    			display: table-cell;
	    		padding: 0 15px 0 5px;
	    		margin-right: 10px;
	    		border-right: 1px solid var(--color-green-line);
	    	}

	    	span{
	    		padding-left: 10px;
	    	}
	    }

	    &:nth-child(3), &:nth-child(4){
	    	a{
	    		color: var(--color-font);
	    	}
	    }

	    &:last-child{
	    	border-color: var(--color-orange);
	    	background: var(--color-orange);
	    	padding: 0 20px;
	    	a{
		    	color: white;
		    }
	    }
	}

}
.woocommerce img.site-logo
,
.site-logo{
	max-width: 120px;

	@media only (--portable){
		max-width: 100px;
	}
}

#masthead #mainnav>div>ul{
	@media only (--desktop){
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	a{
		font-family: 'font-r';
		text-transform: uppercase;
	}
}

body p{
	font-family: 'font-r';
}

body{
	h1,h2,h3,h4,h5,h6{
		font-family: 'font-r';
	}
}

@media only (--not_phone){
.mobile-contact{
	display: none;
}
}

@media only (--portable){
	.head-contact{
		display: none;
	}
	.mobile-contact{

		p{
			padding: 0 10px;
	    	border: 1px solid var(--color-green-line);
	    	border-radius: 10px;
	    	line-height: 24px;
	    	margin-bottom: 0;

	    	a{
	    		color: var(--color-font);
	    	}
		}

		.row1{
			display: flex;
			margin-bottom: 10px;
			justify-content: space-between;

			p{
				margin-bottom: 0;
				padding: 0 10px;
		    	border: 1px solid var(--color-green-line);
		    	border-radius: 10px;
		    	line-height: 24px;
				display: table;

				a{
					display: table;
				}

				i{
	    			display: table-cell;
		    		padding: 0 15px 0 5px;
		    		margin-right: 10px;
		    		border-right: 1px solid var(--color-green-line);
		    	}

		    	span{
		    		padding-left: 10px;
		    	}

			}
		}
		.row2{
			display: flex;
			margin-bottom: 10px;
			justify-content: flex-end;

			p{
				margin-bottom: 0;
				margin-left: 10px;

				&:last-child{
					border-color: #edaa15;
				    background: #edaa15;
				    padding: 0 20px;

				    a{
				    	color: white;
				    }
				}
			}
		}


	
}
.site .header-clone{
		display: block;
	}
}